.confirmationCard {
    max-width: 550px;
    margin: 0 auto;
    padding: 30px;
}

.oneCard {
    text-align: center;
}
.thanks {
    padding: 30px 0;
}
.successIcon {
    font-size: 100px;
}
p.first {
    padding: 30px 0;
}
p.received {
    font-size: 1rem;
    padding: 15px 0;
}

p.signoff {
    font-size: 1.5rem;
    font-weight: 500;
}

p.theteam {
    font-style: italic;
    padding: 20px 0 50px;
}