.toolbar {
    padding: 10px 0;
    max-width: 800px;
    margin: 0 auto;
}
.menuItem {
    cursor: pointer;
}
.menuItem ion-icon {
    padding-right: 10px;
}
@media screen and (max-width: 602px) {
    .logo {
        height: 60px;
        padding: 10px 0;
    }
}