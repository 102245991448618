ion-card {
    background: #fff; 
}

.registerCard {
    /* width: 98%; */
    /* min-width: 360px; */
    padding: 5%;
    max-width: 800px;
    margin: 0 auto;
}
.register {
    margin-top: 20px;
}
.confirm, .already {
    margin-top: 40px;
}
.confirm ion-button {
    margin-bottom: 30px;
}
.confirm p, .already p {
    text-align: center;
}

@media (prefers-color-scheme: dark) {
    ion-card { background:  #1e1e1e; color: white; }
    ion-item { --border-color: #aaa}
  }
  