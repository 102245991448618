ion-card {
    background: #fff; 
}

.infoIcon {
    position: relative;
    top: 4px;
    cursor: pointer;
}
.boatSize {
    position: relative;
    padding-top: 10px;
}

.uppercase {
    text-transform: uppercase;
}
@media (prefers-color-scheme: dark) {
    ion-card { background:  #1e1e1e; color: white; }
    ion-item { --border-color: #aaa}
  }
  