.bookingsCard {
    max-width: 600px;
    margin: 0 auto;
}
h3.dateTime {
    font-size: 20px;
    font-weight: 700;
}
.expires {
    font-size: 18px;
    font-weight: 500;
    padding-top: 5px;
}
.cancel {
    font-size: 13px;
}
.where {
    padding-top: 5px;
}
p.summary {
    font-size: 1.25em;
}
@media screen and (max-width: 602px) {
    h3.dateTime {
        font-size: 15px;
        font-weight: 700;
    }
}