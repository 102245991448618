body {
    --ion-background-color: #f6f7f9;
}
ion-card {
    background: #fff; 
}
.logo {
    max-width: 300px;
    margin: 20px auto;
}
.inTheMiddle {
    max-width: 90%;
    margin: 40px auto;
}
.logoArea {
    flex-direction: row;
}
.loginCard {
    max-width: 800px;
    margin: 0 auto;
}
.loginCardLeft {
    padding: 10% 8%;
}
.loginCardRight {
    padding: 10% 8%;
    background-color: #26407F;
    text-align: center;
}
.inputLabel {
    color: #aaa !important
}
.loginButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60%;
    color: #aaa;
    padding-top: 20px;
}
.loginButton {
    text-transform: none;
    height: 40px;
}
.loginButton span{
  text-align:left;
  width:100%;
}
.full {
    width: 100%;
    --border-width: 1px;
    --border-color: #ddd;
    margin-bottom: 15px;
}
.or {
    padding: 10px 0;
}

h2.loginCTA {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.4em;
    padding: 0 0 30px;
    color: #fff;
}
.loginCTA span{
    font-weight: 800;
}
p.loginReason {
    color: #fff;
    margin-top: 20px;
}
p.heavy {
    font-weight: 800;
    font-size: 1.25em;
}
ion-item {
    --ion-background-color: #fff;
}

@media only screen and (max-width: 600px) {
    .inTheMiddle {
        margin: 30px auto;
        max-width: 95%;
    }
}
@media (prefers-color-scheme: dark) {
    ion-card { background:  #1e1e1e; color: white; }
    /* ion-item { --ion-background-color:  #1e1e1e; --border-color: #aaa} */
    .loginButton {
        --border-color: #fff;
    }

  }
  