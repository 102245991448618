.locationsList {
    height: 100%;
    overflow: scroll;
    padding-bottom: 100px;
}
.locCard {
    max-width: 800px;
    margin: 0 auto;
}
.locCard, 
.locationsList,
.legendArea {
    -ms-overflow-style: none;
}
.locCard::-webkit-scrollbar, 
.locationsList::-webkit-scrollbar,
.legendArea::-webkit-scrollbar {
    display: none;
}
.locationIcon {
    height: 40px;
    width: 40px;
}
.launchItem {
    cursor: pointer;
}
.selectedOverlay {
    min-height: 100%;
    width: 100%;
}
.backButton {
    --padding-start: 0;
}
.imgHolder {
    width: 100%;
    height: 225px;
}
h2.locName {
    font-weight: 800;
    font-size: 1.7rem;
}
.locImage {
    padding: 2%;
    width: 100%;
    max-width: 100%;
}
.please {
    padding-bottom: 20px;
}
h3.address {
    font-weight: 700;
    font-size: 1rem;
    padding: 10px 0 20px;
}
h3.noticeHeading {
    font-size: 1.2rem;
    font-weight: 800;
}
p.notices {
    font-size: 1rem;
    font-weight: 500;
    padding-bottom:20px;
}
.confirmHolder {
    padding: 30px 0;
}
.center {
    text-align: center;
}
.dark .rezWarning p {
    color: #fff
}
.half {
    max-width: 50%;
}
.availModal {
    text-align: center;
    margin: auto;
    position: relative;
    top: 0
}
.alertIcon {
    font-size: 100px;
    text-align: center;
}
.inOutArea {
    margin-right: 10px;
    position: relative; 
    top: 3px;
}
.inOut {
    font-size: 20px;
}
.picker-wrapper.sc-ion-picker-md {
    bottom: 50%;
}
.timeSlot {
    cursor: pointer;
}
.legendArea {
    padding-top: 10px;
    padding-left: 10px;
}
.legend {
    font-size: 20px;
    position: relative;
    top: 5px;
    margin: 0 5px;
}
.loggedIn {
    font-size: 0.8rem;
}
ul.statuses {
    list-style: none;
    margin: 0;
    padding-left: 0;
}
ul.statuses li.status {
    display: inline;
}
.calIcon {
    font-size: 24px;
    position: relative;
    top: 6px;
    margin-right: 10px;
}
.notiModal .ion-page {
    background: #fff;
}

@media screen and (max-width: 602px) {
    .locationIcon {
        height: 30px;
        width: 30px;
    }
    .locImage {
        padding: 0;
        width: 100%;
        max-width: 100%;
    }
    .locationsList {
        height: 100%;
        overflow: scroll;
        padding-bottom: 50px;
    }
    .half {
        max-width: 100%;
    }
    ul.statuses li.status {
        display: block;
    }
}
@media screen and (max-width: 321px) {
    .notiModal .ion-page {
        font-size: 13px;
    }
    .notiModal .ion-page h3 {
        font-size: 14px;
    }
}
.modal-wrapper {
    min-height: 600px !important;
}